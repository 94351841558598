import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const serviços = () => {
  return (
    <Layout>
      <Seo
        title="Serviços"
        description="No Restaurante Monte Alegre estamos ao seu dispor para Batizados, Comunhões, Karaoke, Festas de aniversário, etc."
        defer={false}
      />
      <div className="w-full mx-auto lg:mt-10">
        {/*                                 BATISMO SECTION                                    */}
        <div className="flex flex-col w-full h-20 bg-gray-200 justify-center items-center text-md lg:h-32 lg:text-xl lg:font-medium ">
          <h1>Estamos ao seu dispor</h1>
          <span className="text-xs font-extralight lg:text-base">
            Experimente e apaixone-se
          </span>
        </div>
        <main>
          <div className="baptismo-image h-28 w-full bg-no-repeat bg-center bg-cover  lg:h-48"></div>
          <section className=" flex flex-col w-full h-full pt-5 lg:my-16">
            <h1 className="text-center text-red-700 font-medium lg:text-lg">
              Batismo
            </h1>
            <div className=" flex  w-4/5 mx-auto py-5 lg:gap-32 lg:justify-center lg:items-center">
              <img
                src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,w_433/v1639668249/novas%20fotos/1b2dvrZ6lwkWx5s_0ejBNvumSdYKeAU5D_kiupj3.jpg"
                alt=""
                className="hidden lg:block lg:rounded-tl-3xl lg:rounded-br-3xl"
              />
              <div className="flex gap-3 flex-col lg:w-2/5 ">
                <h2 className="lg:text-lg">
                  O Monte Alegre é o local perfeito para realizar uma data tão
                  importante!
                </h2>
                <p className=" font-extralight text-xs lg:text-base">
                  O batizado é a transformação da água em bênçãos de Deus. Que a
                  criança que o receberá possa saber que Deus estará sempre com
                  ela, auxiliando em todos os momentos e dificuldades.{" "}
                </p>
                <div className="flex w-full  justify-between items-center">
                  <span className="text-xs font-medium lg:text-sm">
                    Sabemos fazer o melhor.
                  </span>
                  <Link to="/menu">
                    <button
                      type="button"
                      className=" text-xs font-medium shadow-md h-8 px-2  text-gray-700 transition-colors duration-150 bg-yellow-500 rounded-sm focus:shadow-outline hover:bg-yellow-600 md:px-5 md:h-8 lg:text-sm"
                    >
                      A nossa Ementa
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </section>
          {/*                                 COMUNHOES SECTION                                  */}
          <div className="comunhao-image h-28 w-full bg-no-repeat bg-center bg-cover lg:h-48"></div>
          <section className=" flex flex-col w-full h-full pt-5 lg:my-16">
            <h1 className="text-center text-red-700 font-medium lg:text-lg">
              Comunhões
            </h1>
            <div className=" flex  w-4/5 mx-auto py-5 lg:gap-32 lg:justify-center lg:items-center">
              <img
                src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,w_433/v1639668251/novas%20fotos/1bcivqnswGfUcAZhi1Om5NT6Jbx-PNsn9_xtnbeb.jpg"
                alt=""
                className="hidden lg:block lg:rounded-tl-3xl lg:rounded-br-3xl "
              />
              <div className="flex gap-3 flex-col  lg:w-2/5">
                <h2 className="lg:text-lg">
                  O Monte Alegre é o local perfeito para realizar uma data tão
                  importante!
                </h2>
                <p className=" font-extralight text-xs lg:text-base">
                  Nós achamos que a comunhão não é um prêmio para os virtuosos,
                  mas um alimento para os famintos..{" "}
                </p>
                <div className="flex w-full  justify-between items-center">
                  <span className="text-xs font-medium lg:text-sm">
                    Sabemos fazer o melhor.
                  </span>
                  <Link to="/menu">
                    <button
                      type="button"
                      className=" text-xs font-medium shadow-md h-8 px-2  text-gray-700 transition-colors duration-150 bg-yellow-500 rounded-sm focus:shadow-outline hover:bg-yellow-600 md:px-5 md:h-8 lg:text-sm"
                    >
                      A nossa Ementa
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </section>
          {/*                                 KARAOKE        SECTION                                     */}
          <div className="karaoke-image h-28 w-full bg-no-repeat bg-center bg-cover lg:h-48"></div>
          <section className=" flex flex-col w-full h-full pt-5 lg:my-16">
            <h1 className="text-center text-red-700 font-medium lg:text-lg">
              Karaoke/Outros Eventos
            </h1>
            <div className=" flex  w-4/5 mx-auto py-5 lg:gap-32 lg:justify-center lg:items-center lg:mb-20">
              <img
                src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,w_433/v1637680115/Fotos%20do%20FrontEnd/servi%C3%A7os%20page/micro_ljthch.jpg"
                alt=""
                className="hidden lg:block lg:rounded-tl-3xl lg:rounded-br-3xl"
              />
              <div className="flex gap-3 flex-col  lg:w-2/5 ">
                <h2 className="lg:text-lg">
                  O Monte Alegre é o local perfeito para se divertir!
                </h2>
                <p className=" font-extralight text-xs lg:text-base">
                  Quando algum empolgado do seu grupo fala “hoje eu quero cantar
                  todas", você sabe que a noite vai ser muito longa.{" "}
                </p>
                <div className="flex w-full  justify-between items-center">
                  <span className="text-xs font-medium lg:text-sm">
                    Sabemos fazer o melhor.
                  </span>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </Layout>
  )
}

export default serviços
